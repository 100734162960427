/**
 * 退款单API
 */

import request from '@/utils/request'

/**
 * 查询卡券订单详情功能
 * @param params
 * @returns {Promise<any>}
 */
export function getCouponOrderDetail(params) {
  return request({
    url: 'seller/trade/orders/seller/cards/sell/info',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 查询卡券退单详情功能
 * @param params
 * @returns {Promise<any>}
 */
export function getRefundCouponOrderDetail(params) {
  return request({
    url: 'seller/trade/orders/seller/cards/refund/sell/info',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 通过卡号段查询卡号功能
 * @param params
 * @returns {Promise<any>}
 */
export function getQueryCardNumber(params) {
  return request({
    url: 'seller/trade/orders/seller/cards/number',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 商家获取退款/货单列表
 * @param params
 * @returns {Promise<any>}
 */
export function getRefundList(params) {
  return request({
    url: 'seller/after-sales/shopRefundsList',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 供应商获取退款/货单列表
 * @param params
 * @returns {Promise<any>}
 */
export function getSellerRefundList(params) {
  return request({
    url: 'seller/after-sales/sellerRefundsList',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 企业获取退款/货单列表
 * @param params
 * @returns {Promise<any>}
 */
export function enterpriseRefundsList(params) {
  return request({
    url: 'seller/after-sales/enterpriseRefundsList',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 退款/货 详情
 * @param sn
 * @returns {Promise<any>}
 */
export function getRefundDetails(sn) {
  return request({
    url: `seller/after-sales/refunds/${sn}`,
    method: 'get',
    loading: false
  })
}

/**
 * 卖家审核退款/退货
 * @param sn
 * @param params
 * @returns {Promise<any>}
 */
export function refundAuth(sn, params) {
  return request({
    url: `seller/after-sales/audits/${sn}`,
    method: 'post',
    loading: false,
    data: params
  })
}

/**
 * 卖家执行退款
 * @param sn
 * @param params
 * @returns {Promise<any>}
 */
export function toRefund(sn) {
  return request({
    url: `seller/after-sales/refunds/${sn}`,
    method: 'post',
    loading: false
  })
}

/**
 * 入库操作
 * @param sn
 * @param params
 * @returns {Promise<any>}
 * @constructor
 */
export function wareHousing(sn, params) {
  return request({
    url: `seller/after-sales/stock-ins/${sn}`,
    method: 'post',
    loading: false,
    data: params
  })
}

/**
 * 发货
 * @param sn
 * @param params
 * @returns {Promise<any>}
 * @constructor
 */
export function sellerShip(sn, params) {
  return request({
    url: `seller/after-sales/sellerShip/${sn}`,
    method: 'post',
    loading: false,
    data: params
  })
}

/**
 * 拒绝发货
 * @param sn
 * @param params
 * @returns {Promise<any>}
 * @constructor
 */
export function sellerShipRefuse(sn, params) {
  return request({
    url: `seller/after-sales/sellerShipRefuse/${sn}`,
    method: 'post',
    loading: false,
    data: params
  })
}

/**
 * 套餐卡2.0获取售后列表
 * @param params
 * @returns {Promise<any>}
 */
export function getAIRefundList(params) {
  return request({
    url: '/seller/after-sales/shopRefundsListTh',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 套餐卡2.0获取售后订单详情
 * @param sn
 * @returns {Promise<any>}
 */
export function getAIRefundDetail(sn) {
  return request({
    url: `/seller/after-sales/refundsTh/${sn}`,
    method: 'get',
    loading: false
  })
}

/**
 * 智能套餐卡审核售后
 * @param sn
 * @param params
 */
export function refundAIAuth(sn, params) {
  return request({
    url: `/seller/after-sales/auditsTh/${sn}`,
    method: 'post',
    loading: false,
    data: params
  })
}
