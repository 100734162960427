
import { Component, Vue } from "vue-property-decorator";
import * as API_refund from "@/api/refund";
import * as API_Order from "@/api/order";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import XDialog from "@/components/x-dialog/x-dialog.vue";
import * as API_order from "@/api/order";
import { refundAIAuth } from "@/api/refund";

@Component({
  name: 'refundDetail',
  components: {
    XDialog
  },
  filters: {
    trade_status(status) {
      switch (status) {
        case 'NEW':
          return '新订单'
        case 'CONFIRM':
          return '待付款'
        case 'PAID_OFF':
          return '待发货'
        case 'SHIPPED':
          return '已发货'
        case 'ROG':
          return '已收货'
        case 'COMPLETED':
          return '已完成'
        case 'COMPLETE':
          return '已完成'
      }
    },
    filterKind(data) {
      switch (data) {
        case 1:
          return '月卡'
        case 2:
          return '季卡'
        case 3:
          return '年卡'
        case 4:
          return '自定义'
      }
    }
  }
})

export default class refundDetail extends Vue {
  loading = false;
  rowKeysList: any = [];
  orderDetail: any = {};
  sn = this.$route.params.sn;
  order_source = '';
  refund_detail: any = {};
  productList: any = [];
  allData: any = {};
  expressInfo: any = [];
  activeName = '1';
  imgList = [];
  supplierExpressInfo: any = null;
  buyerExpressInfo: any = null;
  comboInfo: any = {};
  videoUrl: any = '';
  videoding: any = false;
  newTradeSnList: string = '';
  /** 审核 */
  refundAudit = $xDialog.create({
    title: '审核',
    width: '30vw',
    beforeConfirm: () => this.handlerAuditAdopt()
  });
  refundInfo: any = {};
  auditForm: any = {
    refund_type: '1',
    reason: ''
  };
  refund_img = [];
  refundMoney = 0;

  mounted() {
    this.GET_OrderDetail();
  }
  playVideo(src) {
    this.videoUrl = src;
    this.videoding = true;
  }

  /** 获取订单详情信息 */
  GET_OrderDetail() {
    this.loading = true;
    API_refund.getAIRefundDetail(this.sn).then(res => {
      this.loading = false;
      if (!res.state) {
        res.state = 0
      }
      this.orderDetail = res.trade_do;
      this.allData = res;
      this.newTradeSnList = res.new_trade_sns.map(item => item.trade_sn).join(',')

      if (!this.orderDetail) return this.$message.error('数据加载失败！');

      let order_total_price = 0
      res.refund_goods.forEach((item) => {
        order_total_price += item.shop_supplier_price * item.ship_num
      });

      this.orderDetail['order_total_price'] = order_total_price
      this.productList = res.refund_goods;

      if (res.delivery_json) {
        this.expressInfo = JSON.parse(res.delivery_json)
      } else {
        this.activeName = '2'
      }

      this.refund_detail = res.refund;
      this.order_source = res.source;

      if (this.refund_detail.refund_img) {
        this.imgList = this.refund_detail.refund_img.split(',')
      }

      if (this.expressInfo.length) {
        if (!this.expressInfo[0].logiId) {
          this.expressInfo[0].logiId = 12
        }
      }
      let params = {}
      params['order_sn'] = this.refund_detail.order_sn
      params['sku_id'] = this.productList[0].sku_id
      params['ext_goods_id'] = this.productList[0].ext_id
      params['is_combo'] = 0
      if (this.expressInfo.length) {
        API_Order.getExpressListBySkuIdNew(params).then(res => {
          if (res.code === 1) {
            this.expressInfo[0]['data'] = res.data
            this.expressInfo[0]['data']['orderTrack'].reverse()
            this.expressInfo[0]['code'] = 1
          } else {
            this.expressInfo[0]['data'] = res.data
            this.expressInfo[0]['message'] = res.message
          }
          console.log(this.expressInfo)
          this.$forceUpdate()
        }).then(() => {
          // 供应商物流信息
          if (this.refund_detail.seller_logi_id && this.refund_detail.seller_ship_no) {
            API_Order.getLogisticsInfo({
              id: this.refund_detail.seller_logi_id,
              num: this.refund_detail.seller_ship_no
            }).then(res => {
              this.supplierExpressInfo = res
            })
          }
          // 买家回寄物流信息
          if (this.refund_detail.customer_logi_id && this.refund_detail.customer_ship_no) {
            API_Order.getLogisticsInfo({
              id: this.refund_detail.customer_logi_id,
              num: this.refund_detail.customer_ship_no
            }).then(res => {
              this.buyerExpressInfo = res
            })
          }
        })
      }
      //获取卡券信息
      API_order.getComboInfo({ card_code: res.trade_do.card_code, shop_id: res.trade_do.shop_id }).then(response => {
        this.comboInfo = response;
      })
    })
  }

  /** 审核 */
  handleOpenAuditDialog(sn) {
    API_refund.getRefundDetails(sn).then((response) => {
      this.refundAudit.display();
      this.refundInfo = response.refund
      this.refundMoney = response.refund.refund_shop_price
      if (this.refundInfo.refund_img) {
        this.refund_img = this.refundInfo.refund_img.split(',')
      }
    })
  }

  handlerAuditAdopt() {
    const _params = {
      agree: this.auditForm.refund_type,
      reason: this.auditForm.refund_reason,
      refund_shop_price: this.refundMoney,
      sn: this.refundInfo.sn,
    }
    API_refund.refundAIAuth(this.refundInfo.sn, _params).then(() => {
      this.refundAudit.dismiss();
      this.GET_OrderDetail();
    });
    return false;
  }
}
